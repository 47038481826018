<template>
	<div id="wrap">
		<top page="about" :showSearchButton="false" />
		<div class="mobile-wrap">
			<div class="module-1">
				<div class="mainBox">
					<div class="main-title">
						<img src="../style/img/about.svg" />
						<h2>About Us</h2>
					</div>
					<div class="list-box">
						<div class="list">
							<h2>花艺课堂</h2>
							<span>Floral Course</span>
						</div>
						<div class="list">
							<h2>艺术展览</h2>
							<span>Art exhibition</span>
						</div>
						<div class="list">
							<h2>商业设计</h2>
							<span>Commercial design</span>
						</div>
					</div>
				</div>
			</div>
			<!-- module-1 -->
			<div class="module-2">
				<div class="mainBox">
					<div class="main-title">
						<h2>Contact Us</h2>
					</div>
					<div class="list-box">
						<div class="list" v-if="typeof about.wechat !== 'undefined' && about.wechat !== ''">
							<h2>商务微信 | WeChat:</h2>
							<span>{{about.wechat}}</span>
						</div>
						<div class="list" v-if="typeof about.wechat !== 'undefined' && about.wechat !== ''">
							<h2>公众号 | Official Accounts:</h2>
							<span>{{about.mpwechat}}</span>
						</div>
						<div class="list" v-if="typeof about.douyin !== 'undefined' && about.douyin !== ''">
							<h2>抖音号 | Tik Tok:</h2>
							<span>{{about.douyin}}</span>
						</div>
						<div class="list" v-if="typeof about.email !== 'undefined' && about.email !== ''">
							<h2>邮件 | E-mail:</h2>
							<span>{{about.email}}</span>
						</div>
						<div class="list" v-if="typeof about.address !== 'undefined' && about.address !== ''">
							<h2>地址 | Address:</h2>
							<span>{{about.address}}</span>
						</div>
						<div class="list" v-if="typeof about.workTime !== 'undefined' && about.workTime !== ''">
							<h2>工作时间 | Working Time:</h2>
							<span>{{about.workTime}}</span>
						</div>
					</div>
				</div>
			</div>
			<!-- module-2 -->
		</div>
		<bottom />
		<side />
	</div>
</template>

<script>
import top from "./components/top.vue";
import bottom from "./components/bottom.vue";
import side from "./components/side.vue";
import { wxShare } from "@/utils/share.js";
export default {
	page: {
		title: '联系我们'
	},
	methods: {
		handleWxShare() {
			let url = window.location.href

			this.$api.wechat.mpwechat.getJsapiSignature(url)
			.then(response => {
				if(response.code === '0') {

					let browser = window.navigator.userAgent.toLowerCase();	

					// 非微信浏览器不执行分享代码
					if (browser.match(/MicroMessenger/i) != 'micromessenger') {
						return;
					} 	

					//微信加签
                    let obj = {
                        appId: response.data.appId,
                        nonceStr: response.data.nonceStr,
                        signature: response.data.signature,
                        timestamp: response.data.timestamp,
                        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
                    };


                    // var shareWxLink = encodeURIComponent(url);加密
                    let shareData = {
                        title: 'C-FLROAL线下活动报名', // 分享标题
                        desc: '名师全视频干货解析，当下全球最新流行的花艺技法与造型',
                        link: response.data.url,
                        imgUrl: 'http://www.c-floral.net/images/share/logo-02.jpg' // 分享图标
                    };

					//引用
                    wxShare(obj, shareData);
				}
				else {
					this.$message.info(response.message)
				}
			})
		},
		handleGetSingle() {
			this.$api.contact.aboutUs.getSingle()
			.then(response => {
				if(response.code === '0') {
					this.about = response.data
				}
				else {
					this.$message.error(response.message)
				}
			})
		}
	},
	data() {
		return {
			about: {}
		}
	},
	mounted() {
		this.handleGetSingle()
	},
	components: {
		top,
		bottom,
		side
	},
}
</script>

<style src="../style/css/about.css" scoped></style>
<style scoped>

</style>